import React from "react"
import { Link } from "gatsby"

import LogoText from "../assets/svg/rentware-logo-text.svg"
import LogoIcon from "../assets/svg/rentware-logo-icon.svg"

const NavDe = (props) => (
    <nav className="bg-white">
        <ul className="flex flex-wrap items-center justify-between mx-auto text-sm max-w-screen-3xl md:h-28 md:justify-start lg:py-2">
            <li className="w-auto p-4">
                <Link to="/de/">
                    <LogoIcon className="w-16 h-10 md:inline-block md:" />
                    <LogoText className="w-16 h-auto mt-2 md:inline-block md:w-28" />
                </Link>
            </li>
            {props.telNumber &&
                <li className="order-first w-full py-2 text-center bg-primary-bg md:bg-transparent md:ml-auto md:w-auto md:p-4 md:order-none">
                    <a 
                    className="px-2 py-4 md:mb-0 md:btn md:btn--tertiary"
                    href={"tel:+49309203854012"}
                    >
                        <strong className="mr-4">Antworten erhalten</strong>
                        +49 30 9203854012
                    </a>
                </li>
            }
            {(props.contactCtaMobile || props.contactCtaDesktop) &&
                <li className="flex-grow p-4 font-bold text-right md:flex-grow-0">
                    <Link to="/de/kontakt" className="mb-0 btn btn--secondary">Kontakt</Link>
                </li>
            }
        </ul>
    </nav>
)
    
export default NavDe